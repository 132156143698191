import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  class: "card card-bordered mb-5",
  id: "empRef"
}
const _hoisted_6 = { class: "mb-5 text-center" }
const _hoisted_7 = { class: "card-footer" }
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_vc_information_modal = _resolveComponent("vc-information-modal")!
  const _component_vc_health_corporate_top = _resolveComponent("vc-health-corporate-top")!
  const _component_vc_health_employee = _resolveComponent("vc-health-employee")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_vc_health_last = _resolveComponent("vc-health-last")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "card-title" }, "New Group", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: 'CustomerLists' },
          class: "btn btn-sm btn-flex btn-info btn-active-info fw-bolder",
          "data-kt-menu-trigger": "click",
          "data-kt-menu-placement": "bottom-end",
          "data-kt-menu-flip": "top-end"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }, [
              _createElementVNode("i", { class: "far fa-arrow-alt-circle-left" })
            ], -1),
            _createTextVNode(" Back ")
          ])),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_vc_information_modal),
    _createVNode(_component_el_form, {
      ref: "corporateForm",
      model: _ctx.health,
      "status-icon": "",
      rules: _ctx.rules,
      id: "healthForm",
      onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.submitHealth(_ctx.corporateForm)), ["prevent"])),
      "scroll-to-error": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_vc_health_corporate_top, {
            ref: "top",
            onReValidate: _ctx.revalidate,
            onTab: _ctx.checkform,
            onCheckPhoneExist: _ctx.checkPhoneExist
          }, null, 8, ["onReValidate", "onTab", "onCheckPhoneExist"]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "separator border-2 my-10" }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-header ribbon mb-5 mt-5" }, [
              _createElementVNode("div", { class: "card-title mb-5" }, [
                _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Group Details ")
              ])
            ], -1)),
            _createVNode(_component_vc_health_employee, { ref: "empRef" }, null, 512),
            _createElementVNode("div", null, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "separator border-2 mb-5" }, null, -1)),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_el_button, {
                  type: "button",
                  class: "btn btn-lg btn-info w-10 h-auto",
                  onClick: _ctx.addNewCategory
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Add Category ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          (_ctx.store.getters.showByRole([1, 9]))
            ? (_openBlock(), _createBlock(_component_vc_health_last, {
                key: 0,
                ref: "last"
              }, null, 512))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_router_link, {
                  to: { name: 'CustomerLists' },
                  class: "btn btn-secondary me-3"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Cancel ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_vc_button_save, { loading: _ctx.loading }, null, 8, ["loading"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}